<template>
    <PageHeader :title="title" :items="items" />

    <!-- Проекти в базі знань -->
    <kwProjects v-if="!this.$route.params.id" />

    <!-- База знань по проектам -->
    <knowledge 
      v-if="this.$route.params.id"
    />

</template>

<script>
import PageHeader from "@/components/page-header";
import knowledge from "@/components/knowledge-base/index";
import kwProjects from './projects/index' // проекти бази знань todo dev
// import axios from 'axios';


export default {
  data() {
    return {
      title: this.$t('Knowledge_base'),
      items: [
        {
          text: this.$t('Knowledge_base'),
          href: "/",
        }
      ],
      knowledgeBaseSearchQuery: '',
      kb: [],
      child: []
    };
  },
  methods: {
    
  },
  computed: {
  },
  mounted() {
    
  },
  created() {
  },
  components: {
    PageHeader,
    knowledge,
    kwProjects
  },
};
</script>

<style scoped>
.knowledge-base-bg {
    padding: 70px;
    background: #374158;
    color: white
}
.text-h2 {
    color: #4caf50;
    font-size: 37px;
}
</style>