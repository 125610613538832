<template>
    <!-- 
      Сторінка з проектами по базі знань
    -->

    <!-- Компонент хедеру розділу -->
    <!-- <PageHeader :title="title" :items="items" /> -->
    
    <section id="kb-category-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage:`url(${require('@/assets/images/back/back_faq_1.png')})`}"
      >
      <div class="row btn-dot" v-if="this.perms[802]">
          <div class="col text-start dropdown">
            <button 
              type="button" 
              id="dropdownMenuLink2"
              data-bs-toggle="dropdown"
              aria-expanded="false" 
              class="btn btn-soft-success waves-effect fw-semibold" 
              style="margin-right: 10px;"
            >
              <i class="ri-add-line"></i>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="dropdownMenuLink2"
            >

              <!-- 
                Кнопка "Додати проект" 
                - надає змогу створити проект
                - йде перевірка на права доступу під номером 802 (створення уроку/категорії) та 2000 (режим бога)
              -->
              <li>
                <a
                  v-if="perms[802] || perms[2000]"
                  class="dropdown-item copy"
                  @click="showModalAdd = true"
                >
                  <i class="bx bx-folder-plus me-2 align-middle"></i>
                  {{ $t('AddProject') }}
                </a>
              </li>
              
            </ul>
          </div>
        </div>

        <!-- Заголовок розділу та поле для введення пошукового запиту -->
        <b-card-body class="card-body" style="text-align: left;">
          <h2 class="text-h2">
            {{ $t('navProjects') }} | {{ $t('Knowledge_base') }}
          </h2>
          

          <!-- Поле пошуку -->
          <b-form class="kb-search-input" style="max-width: 500px;">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="searchbar"
                v-model="this.objParams.search"
                @input="searchData($event)"
                :placeholder="$t('search_word')"
              />
            </b-input-group>
          </b-form>
        </b-card-body>
      </b-card>
    </section>
    
    <!-- Список проектів -->
    <div class="row" >
      <template v-for="item in rows" :key="item">
          <div 
            class="col-md-4" 
            @contextmenu.prevent="showContextMenu($event, item)"
            @click="$router.push(
              { 
                name: 'pages-open-project-kw', 
                params: { 
                    id: item.projectId, 
                    title: item.projectName
                } 
              }
            )"
          >
            <div class="card card-body card-animate" style="cursor: pointer;" >
              <div class="d-flex mb-4 align-items-center" >
                <div class="flex-shrink-0" >
                  <!-- Лого -->
                  <template v-if="item.icon != '0'" >
                    <img :src="item.icon" alt="POS-Термінал Logo" class="avatar-xl rounded">
                  </template>
                  <template v-else>
                    <div 
                      class="avatar-xl" 
                      style="margin: 0px auto;"
                    >
                      <div 
                        class="avatar-title rounded bg-success text-white" 
                        style="font-size: 50px;font-weight: 600;"
                      >
                        {{ this.getInitials(item.projectName != '' ? item.projectName : 'Без назви') }} 
                      </div>
                    </div>
                  </template>
                  <!-- <img src="/img/posterminal.7263caf4.png" alt="POS-Термінал Logo" class="avatar-xl rounded"> -->
                </div>
                <div class="flex-grow-1 ms-2" >
                  <!-- Назва -->
                  <h5 class="card-title mb-1 fw-semibold fs-18">{{ item.projectName }}</h5>
                  <!-- Опис -->
                  <p class="text-muted mb-0">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
      </template>
    </div>

    <!-- Картка створення проекту -->
    <newBox 
      v-if="showModalAdd"
      @close="closeCreate()"
      :obj="this.form"
      :perm="this.permToEdit"
    />

    <!-- Контекстне меню -->
    <my-context-menu 
      ref="contextMenu" 
      :btn="btnContextMenu"
      @select="switchCntMenu"
    />

    <!-- Модальне вікно підтвердження видалення папки -->
    <modalremove 
        v-if="dialogRemoveShow" 
        @close="dialogRemoveShow = false" 
        @remove="deleteProject()" 
        :additCheck="true"
    />

</template>

<script>
// import PageHeader from "@/components/page-header"; // хедер розділу
import newBox from "./new" // картка створення проекту
import modalremove from '@/components/modal/modalremove' // модальне вікно підтвердження видалення
import { knowledgeBaseProject } from "@/API" // клас для взаємодії з API 
import { storeS } from "@/store"; // глобальне сховище даних
import { mutateLogoProjects } from '@/usabilityScripts/globalMutate.js'
import MyContextMenu from '@/components/contextMenu/index.vue';

let apiServe = new knowledgeBaseProject();

export default{
    components: {
      // PageHeader,
      newBox,
      MyContextMenu,
      modalremove
    },
    data(){
        return {
          title: this.$t('Knowledge_base'), // Заголовок
          searchTime: '', // Ідентифікатор таймауту для пошукового запиту
          rows: [],  // проекти
          showModalAdd: false, // Прапорець відображення вікна створення проекту
          dialogRemoveShow: false, // Прапорець на відображення вікна видалення
          removeItem: '', // Дані проекту для видалення
          objParams:{ // Параметри для фільтрації даних по проектам
            page: '1',
            pagelimit: '200',
            search: ''
          },
          permToEdit: false, // Прапорець для редагування
          form: '', // Дані папки для редагування
          currentOption: null,
          btnContextMenu: [
            { 
              title: this.$t('Open'),
              icon: 'bx bxs-door-open',
              color: 'info',
              value: 'open',
              status: true,
              perms: null
            },
            { 
              title: this.$t('toEditAp'),
              icon: 'bx bx-rename',
              color: 'info',
              value: 'rename',
              status: true,
              perms: null
            },
            { 
              title: this.$t('Delete'),
              icon: 'bx bx-trash',
              color: 'danger',
              value: 'delete',
              status: true,
              perms: '2000'
            },
          ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
      getdata(){
          /*
              Функція для отримання проектів бази знань
              Викликає API для отримання списку проектів з параметрами з об'єкта objParams.
          */

          apiServe.getProjects(this.objParams).then(result => {
            if(result.status == 'done'){
                this.rows = result.data.items
            } else {
              this.$toast.error(this.$t('error') + " " + result.error[0]);

              var errorText = 'Помилка отримання проектів'; 
              var statusRequest = result.status;
              var api = 'getProjects';
              var fileRequest = 'src/views/knowledge-base/projects/index.vue';
              var params = this.objParams;
              var response = result

              // Викликаємо функцію для відправки повідомлення в телеграм
              sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
              .then(response => console.log('Telegram API response:', response.data))
              .catch(error => console.error('Telegram API error:', error));
            } 
          })
      },
      deleteProject(){
        /*
            Функція для видалення проекту
            Викликає API для видалення проекту з параметром ідентифікатору.
        */

        apiServe.deleteProject(this.removeItem.projectId).then(result => {
            if(result.status == 'done'){
                this.$toast.success(this.$t('Removed'))
                this.dialogRemoveShow = false;
                this.getdata();
            } else {
                this.$toast.error(this.$t('error') + " " + result.error[0]);

                var errorText = 'Помилка видалення папки холодних дзвінків'; 
                var statusRequest = result.status;
                var api = 'deleteProject';
                var fileRequest = 'src/views/knowledge-base/projects/index.vue';
                var params = this.removeItem.projectId;
                var response = result

                // Викликаємо функцію для відправки повідомлення в телеграм
                sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                .then(response => console.log('Telegram API response:', response.data))
                .catch(error => console.error('Telegram API error:', error));
            }
        })
      },
      showContextMenu(event, item) {
        // Метод, який викликається при правому кліку на елементі, передаючи подію та об'єкт item

        // Викликаємо метод показу контекстного меню після оновлення DOM
        this.$nextTick(() => {
          // Виклик методу показу контекстного меню у компоненті contextMenu,
          // передаючи подію та об'єкт item
          this.$refs.contextMenu.showContextMenu(event, item);
        });
      },
      searchData(e) {
        // Пошук проктува за запитом

        clearTimeout(this.searchTime)
        this.objParams.search = e;
        this.searchTime = setTimeout(() => this.getdata(), 500)
      },
      editModal(e){
        // Вмикаємо режим редагування папки
        this.permToEdit = true;
        this.form = e;
        this.showModalAdd = true
      },
      closeCreate(){
        // Закриваємо вікно
        this.permToEdit = false;
        this.showModalAdd = false
        this.getdata();
      },
      switchCntMenu(e, item){
        // Виконуємо обрану дію з контекстного меню
        
        switch (e) {
          case 'open':
            // Відкрити
            this.$router.push(
              { 
                name: 'pages-open-project-kw', 
                params: { 
                    id: item.projectId,
                    title: item.projectName
                } 
              }
            )
            break;
          case 'rename':
            // Редагування
            this.editModal(item)
            break;
          case 'delete':
            // Видалення
            this.removeItem = item;
            this.dialogRemoveShow = true
            break;
        
          default:
            break;
        }
      },
      getLogo(e){
        // Отримуємо лого проекту
        return mutateLogoProjects(e)
      },
      getInitials(string) {
        // Отримання ініціалів
        var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      },
    },
    computed: {
        user() {
          /*
              Повертає дані за користувача з глобального стору.
          */
          return storeS.userbase
        },
        perms() {
          /*
              Повертає дані прав доступу користувача з глобального стору.
          */
          return storeS.perms
        }
    }
}
</script>

<style scoped>
.knowledge-base-bg .card-body{
    padding: 70px;
    color: white
}
.btn-dot {
  margin-top:20px;
  margin-left: 20px;
  color:white;
}
.btn-dot #dropdownMenuLink2{
  color:white;
}
.text-h2 {
    color: #d1e1ff;
    font-size: 37px;
    font-weight: bold;
}

.kb-title-button h6 {
  margin-bottom: 0;
  align-self: center;
}
.kb-title-button {
  display: grid;
  grid-template-columns: 90% 10%;
}
.lesson {
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
}
.lesson p {
  margin-bottom: 0;
}
.lesson button{
  color: rgba(207, 11, 11, 0.877);
  border: none;
  border-radius: 7px;
  height: 100%;
}

.scroll_s {
overflow: auto;
display: block;
max-height: 400px;
}
.scroll_s::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.scroll_s::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
.listLess{
  list-style-type: initial;
  margin-left: 25px;
}

.listLess h6:hover{
  color: #0ab39c;
}

.itemPop {
  margin: 0 3px;
  font-size: 12px;
  cursor: pointer;
}
</style>
